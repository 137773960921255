.Nav {
  width: 100%;
  height: 70px;
  background: #1d1d1d;
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
}

.Nav img {
  width: auto;
  height: 80%;
}

.Nav h1 {
  color: #fff;
  font-family: MainFont;
  font-size: 25px;
  font-weight: 400;
}
