.ChangeLang {
  width: 50px;
  height: 50px;
  position: fixed;
  right: 0px;
  bottom: 5px;
  background: #e966a0;
  border-radius: 10px 0 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.ChangeLang button {
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
}
