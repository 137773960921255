.Sec-2 {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  gap: 10px;
  padding: 50px 10% 50px 10%;
}

.Sec-2 h1 {
  width: 100%;
  font-family: MainFont;
  color: #e966a0;
  font-size: 40px;
  font-weight: 400;
  text-decoration: underline;
  text-align: center;
  text-decoration-thickness: 2px;
  text-underline-offset: 10px;
}

.Sec-2 .Sec-2-cards {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 0 20px 0;
}

.Sec-2-cards div {
  width: 350px;
  height: 150px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  gap: 20px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
  flex-direction: column;
}

.Sec-2-cards div h1 {
  font-size: 20px;
  width: 100%;
  text-align: start;
}

.Sec-2-cards div p {
  font-size: 16px;
  width: 100%;
  text-align: start;
}

.Sec-2 button {
  width: 300px;
  height: 40px;
  margin-top: 20px;
  border-radius: 15px;
  border: none;
  cursor: pointer;
  background-color: #e966a0;
  color: #fff;
  font-family: MainFont;
  font-size: 25px;
  font-weight: 400;
  box-shadow: 0px 0px 15px 5px rgba(233, 102, 160, 0.6);
  transition: all 0.3s ease-in-out;
}

.Sec-2 button:hover {
  box-shadow: 0px 0px 25px 10px rgba(233, 102, 160, 0.8);
  transform: scale(1.05);
}
