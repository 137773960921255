.Book {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  gap: 10px;
  padding: 50px 10% 50px 10%;
  font-family: MainFont;
}

.Book h1 {
  width: 100%;
  font-family: MainFont;
  color: #e966a0;
  font-size: 40px;
  font-weight: 400;
  text-decoration: underline;
  text-align: center;
  text-decoration-thickness: 2px;
  text-underline-offset: 10px;
}

.booking-form {
  max-width: 500px;
  margin: 0 auto;
  background: #fff;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  font-family: MainFont;
}

.form-group {
  margin-bottom: 20px;
  text-align: left;
}

label {
  display: block;
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 1rem;
  font-family: MainFont;
}

input,
select {
  width: 100%;
  padding: 12px;
  margin-top: 6px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  font-family: MainFont;
}

.checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.checkbox-label {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-family: MainFont;
}

.checkbox-label input {
  margin-right: 10px;
  accent-color: #e966a0;
  width: fit-content;
}

.book-btn {
  width: 100%;
  padding: 15px;
  background: #ff4081;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
  transition: background 0.3s ease;
  font-family: MainFont;
}

.book-btn:hover {
  background: #e0005a;
}

@media (max-width: 768px) {
  .booking-form {
    padding: 20px;
  }

  .Book h1 {
    font-size: 30px;
  }
}

@media (max-width: 480px) {
  .book-btn {
    font-size: 16px;
    padding: 12px;
  }
}
