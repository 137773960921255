.Sec-4 {
  width: 100%;
  height: fit-content;
  background: #1d1d1d;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
}
.Sec-4 .Block-4 {
  width: 400px;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 10px;
  gap: 20px;
}

.Block-4 h1 {
  color: #fff;
  font-size: 50px;
  font-family: MainFont;
}
.Block-4 p {
  color: #e966a0;
  font-size: 25px;
}

.Block-4 .Social {
  width: fit-content;
  height: fit-content;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.Block-5 {
  width: fit-content;
  height: fit-content;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.Block-5 a {
  text-decoration: none;
  cursor: pointer;
}

.Block-5 input {
  width: 300px;
  height: 40px;
  background: transparent;
  border: none;
  border-bottom: 2px #e966a0 solid;
  color: #fff;
  font-family: MainFont;
  font-size: 20px;
  padding-left: 5px;
}

.Block-5 button {
  width: 300px;
  height: 40px;
  margin-top: 20px;
  border-radius: 15px;
  border: none;
  cursor: pointer;
  background-color: #e966a0;
  color: #fff;
  font-family: MainFont;
  font-size: 25px;
  font-weight: 400;
  box-shadow: 0px 0px 15px 5px rgba(233, 102, 160, 0.6);
  transition: all 0.3s ease-in-out;
}

.Block-5 button:hover {
  box-shadow: 0px 0px 25px 10px rgba(233, 102, 160, 0.8);
  transform: scale(1.05);
}
