.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(
    0,
    0,
    0,
    0.3
  ); /* Slight overlay for better visibility */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  opacity: 0;
  animation: fadeIn 0.3s ease-in-out forwards;
}

.popup.hide {
  animation: fadeOut 0.3s ease-in-out forwards;
}

.popup-inner {
  position: relative;
  padding: 32px;
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 450px;
  background: #ffffff;
  gap: 20px;
  border-radius: 15px;
  box-shadow: 1px 0 18px rgba(0, 0, 0, 0.2);
  transform: translateY(-20px);
  opacity: 0;
  animation: slideIn 0.3s ease-in-out forwards;
}

.popup.hide .popup-inner {
  animation: slideOut 0.3s ease-in-out forwards;
}

.popup-inner .close-btn {
  position: absolute;
  top: 16px;
  right: 16px;
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}

.popup-inner .ht {
  position: absolute;
  top: 16px;
  font-size: 16px;
  color: #000;
  font-family: MainFont;
}

.images {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.images h1 {
  color: #000;
}

.popup-inner img {
  width: 70px;
  height: auto;
}

.popup-inner a {
  font-family: MainFont;
  text-decoration: none;
  color: #000;
  font-size: 10px;
}

.popup-inner p {
  font-family: MainFont;
  color: #000;
  position: relative;
  font-size: 10px;
  top: 50px;
}

/* Media Queries */
@media only screen and (max-width: 460px) {
  .popup-inner {
    width: 90%;
  }
}

/* Keyframe Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}
