.Sec-3 {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
  padding: 20px 0 20px 0;
  gap: 50px;
}

.Sec-3 h1 {
  margin-top: 50px;
  width: 80%;
  font-family: MainFont;
  color: #e966a0;
  font-size: 40px;
  font-weight: 400;
  text-decoration: underline;
  text-align: start;
  text-decoration-thickness: 2px;
  text-underline-offset: 10px;
}

.Sec-3 .Block-3 {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.Block-3 p {
  font-size: 20px;
  letter-spacing: 2px;
  text-align: center;
  text-align: start;
  font-family: MainFont;
}

.Sec-3 button {
  width: 300px;
  height: 40px;
  margin-top: 20px;
  border-radius: 15px;
  border: none;
  cursor: pointer;
  background-color: #e966a0;
  color: #fff;
  font-family: MainFont;
  font-size: 25px;
  font-weight: 400;
  box-shadow: 0px 0px 15px 5px rgba(233, 102, 160, 0.6);
  transition: all 0.3s ease-in-out;
}

.Sec-3 button:hover {
  box-shadow: 0px 0px 25px 10px rgba(233, 102, 160, 0.8);
  transform: scale(1.05);
}
