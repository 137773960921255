.Sec-5 {
  width: 100%;
  height: fit-content;
  padding: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  background: #e966a0;
}

.Sec-5 img {
  width: 80px;
}

.Sec-5 h1 {
  color: #fff;
  font-family: MainFont;
  font-weight: 400;
}
