* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  font-family: MainFont;
}

header {
  display: flex;
  height: 70px;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px #ffffff44;
  overflow: hidden;
  background: #1d1d1d;
}

header img {
  width: auto;
  height: 70%;
}

.space {
  width: 200px;
}

.navbar {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  width: fit-content;
  z-index: 1;
}

.navbar button {
  color: #fff;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  font-size: 20px;
  width: 110px;
  height: 40px;
  transition: 0.5s;
  opacity: 10;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  cursor: pointer;
  background: transparent;
  border: none;
  font-family: MainFont;
}

.navbar button:hover {
  color: #e966a0;
}

header .navbtn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

header .innav {
  visibility: hidden;
  overflow: hidden;
}

@font-face {
  font-family: MainFont;
  src: url("../../Fonts/JosefinSans-VariableFont_wght.ttf");
}

@media only screen and (max-width: 1350px) {
  .space {
    width: 150px;
  }
  header {
    padding-left: 50px;
  }
}

@media only screen and (max-width: 1150px) {
  .space {
    width: 50px;
  }
  header {
    padding-left: 50px;
  }
}

@media only screen and (max-width: 1100px) {
  .space {
    width: 10px;
  }
}

@media only screen and (max-width: 1000px) {
  .space {
    width: 300px;
  }
  header {
    padding-left: 0px;
  }
  header .navbtn {
    visibility: visible;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  header .innav {
    visibility: visible;
    flex-direction: column;
  }
  .navbar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    outline: solid 1px #ffffff44;
  }
  .navbar button {
    margin: 1rem;
  }
  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    background: #1d1d1d;
    transition: 1s;
    transform: translateX(100%);
    overflow-y: scroll;
  }
  header .responsive_nav {
    transform: none;
  }
  nav .innav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  nav .innav img {
    margin-bottom: 50px;
  }
  nav a {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 460px) {
  .space {
    width: 200px;
  }
  header {
    padding-left: 0;
  }
}

@media only screen and (max-width: 414px) {
  .space {
    width: 200px;
  }
  header {
    padding-left: 0;
  }
}

@media only screen and (max-width: 360px) {
  .space {
    width: 150px;
  }
  header {
    padding-left: 0;
  }
}
