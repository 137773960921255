.Sec-1 {
  width: 100%;
  height: 600px;
  background: #1d1d1d;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  gap: 200px;
}

.Sec-1 div {
  width: fit-content;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.Sec-1 .Block-1 h1 {
  color: #e966a0;
  font-size: 90px;
  font-weight: 400;
  font-family: MainFont;
}

.Sec-1 .Block-1 p {
  color: #fff;
  width: 500px;
  font-size: 25px;
  font-weight: 400;
  font-family: MainFont;
}

.Sec-1 .Book-Btn {
  width: 300px;
  height: 40px;
  margin-top: 20px;
  border-radius: 15px;
  border: none;
  cursor: pointer;
  background-color: #e966a0;
  color: #fff;
  font-family: MainFont;
  font-size: 25px;
  font-weight: 400;
  box-shadow: 0px 0px 15px 5px rgba(233, 102, 160, 0.6);
  transition: all 0.3s ease-in-out;
}

.Sec-1 .Book-Btn:hover {
  box-shadow: 0px 0px 25px 10px rgba(233, 102, 160, 0.8);
  transform: scale(1.05);
}

.Sec-1 .Block-2 {
  width: 500px;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.Sec-1 .Block-2 img {
  width: 150px;
  background: #fff;
  height: auto;
  border-radius: 15px;
}

@media only screen and (max-width: 1250px) {
  .Sec-1 {
    gap: 5px;
  }
}

@media only screen and (max-width: 1000px) {
  .Sec-1 {
    height: fit-content;
    flex-direction: column;
    gap: 20px;
    padding: 50px 0 50px 0;
  }
  .Sec-1 .Block-1 {
    width: 95%;
    justify-content: center;
    align-items: flex-start;
  }
  .Sec-1 .Block-2 {
    width: 95%;
    height: 300px;
    flex-wrap: wrap;
  }
  .Sec-1 .Block-2 img {
    width: 110px;
    height: auto;
  }
}

@media only screen and (max-width: 680px) {
  .Sec-1 .Block-1 h1 {
    font-size: 55px;
  }
  .Sec-1 .Block-1 p {
    width: 90%;
    font-size: 20px;
  }
  .Sec-1 .Block-2 {
    width: 95%;
    height: 500px;
    flex-wrap: wrap;
  }
}
